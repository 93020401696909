.learn-more{
    color:#fff;
    text-align: center;
    max-width: 1100px;
    margin: 0px auto 70px;
    .learn-content{
        font-size: 56px;
        line-height: 76px;
        color: #fff;
        font-weight: 900;
        letter-spacing: 0.03rem;
        justify-content: center;
        margin-bottom: 30px;
        div{
          color:#fff;
          font-family: "RobotoCondensed-bold";
          letter-spacing: 0.03rem;
        }
    }
    .step-list{
      margin-bottom: 33px;
    }
    .learn-more-btn{
        margin:0 auto;
        padding:0.75rem 2rem;
        height: 40px;
        border-radius: 20px;
        font-size: 1rem;
        line-height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
    }
}
@media screen and (max-width:1120px) {
  .learn-more .learn-content{
    font-size: 48px;
  }
}
@media screen and (max-width:964px) {
  .learn-more .learn-content{
    font-size: 40px;
  }
}
@media screen and (max-width:810px) {
  .learn-more .learn-content{
    font-size: 32px;
  }
}
@media screen and (max-width:654px) {
  .learn-more .learn-content{
    font-size: 26px;
  }
}