.my-story {
    margin-bottom: 134px!important;
    .my-story-container {
        padding: 0 20px;
        min-height: 100vh;
        .my-story-title {
            margin-top: 40px;
            font-weight: 800;
            color: #B5B3BD;
            font-size: 20px;
        }

        .tips {
            margin-top: 14px;
            font-weight: 400;
            color: #B5B3BD;
        }
        .btns-group{
            margin: 22px 0 40px;
            .about-btn{
                color: #fff;
                width: 140px;
                height: 44px;
                border-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .create-btn{
                width: 110px;
                height: 44px;
                border-radius: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            button+button{
                margin-left: 10px;
            }
        }
    }
}
@media screen and (max-width:1113px) {
  .my-story-list{
    justify-content: center;
  }
  
}