.read-earn {
  padding: 50px 0 90px;
  border-bottom: 1px solid rgba(181, 177, 220, 0.15);

  .read-earn-title {
    letter-spacing: 0.03rem;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
    font-family: "RobotoCondensed-bold";
  }

  .reword-for {
    margin-top: 39px;
    
    .reword-title {
      margin-bottom: 16px;
      font-size: 18px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 800;
      color: #FFFFFF;

      .live {
        color: #71FF47;
      }

      .soon {
        color: #E97844;
      }
    }

    .reword-content {
      padding-bottom: 40px;
      font-size: 15px;
      font-family: ArialMT;
      color: #B5B3BD;
    }
    
  }
  .border-bt{
    border-bottom: 1px solid #4B5071;
  }
  .cats {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .collect {
    min-width: 180px;

    a {
      color: #B6B4BE;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }
  }

  .read-earn-content {
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    text-align: justify;
  }

  .earn-card-container {
    margin-top: 50px;

    .devide {
      border-left: 1px dashed rgba(181, 177, 220, 0.15);
      height: 196px;
    }
  }
}

@media screen and (max-width:1130px) {
  .read-earn {
    margin: 0 20px;

    .earn-card-container {
      flex-wrap: wrap;
      justify-content: center;

      .earn-card {
        margin-bottom: 30px;
      }
    }

    .devide {
      display: none;
    }
  }
}