.token-view{
  .token-top{
    .token-title{
      letter-spacing: 0.03rem;
      color: #fff;
      font-size: 35px;
      font-weight: 900;
    }
    .token-desc{
      color: #B5B3BD;
      font-size: 15px;
      span{
        color: #4582FF;
        font-weight: 900;
        font-size: 16px;
      }
    }
  }
  .card-list{
    align-items: stretch;
    margin-bottom: 38px;
  }
}