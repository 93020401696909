.nav-bar {
  padding: 20px;
  position: fixed;
  z-index: 99999;
  width: 100%;
  top: 0;

  .navbar-toggler:focus {
    box-shadow: none;
  }

  #basic-navbar-nav {
    .navbar-nav {
      margin-left: auto;
      align-items: center;

      .nav-link {
        font-weight: 900;
        margin: 0 8px;
        font-family: "RobotoCondensed-bold";
        letter-spacing: 0.03rem;
      }

      button,
      .address-btn {
        font-family: "RobotoCondensed-bold";
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 122px;
        color: #fff;
        border-radius: 20px;
        font-size: 12px;
        height: 30px;
        max-width: 120px;
        margin: 5px;
      }

      .address-btn {
        position: relative;
        background-color: #0d6efd;
        border-radius: 20px;
        height: 30px;
        background-image: url('../../../../static/images/down.png');
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 92% 50%;

        span {
          display: inline-block;
          width: 80%;
          color: #fff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;

          &::after {
            position: absolute;
            right: 4px;
            top: 50%;
          }
        }

        &:hover {
          background-color: #0b5ed7;
        }
      }

      .account-pop {
        transform: translate(0, 0) !important;
        inset: auto !important;
        right: 0px !important;
        top: 40px !important;
        width: 480px;
        max-width: 480px;
        box-sizing: border-box;

        .popover-arrow {
          right: 20px;
          left: auto !important;
          transform: translate(0, 0) !important;
        }

        .balance {
          font-size: 12px;
          font-weight: 900;
          color: #2B42A1;
          letter-spacing: 0.03rem;
        }

        .hold {
          .hold-key {

            font-size: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #4C5489;
          }

          .hold-val {
            height: 34px;
            line-height: 34px;
            font-size: 30px;
            font-family: Arial, Arial;
            font-weight: normal;
            color: #4C5489;
          }
        }

        .earning-title {

          font-size: 26px;
          font-family: Arial, Arial;
          font-weight: normal;
          color: #4C5489;
        }

        .earning-sub {

          font-size: 18px;
          font-family: Arial, Arial;
          font-weight: normal;
          color: #4C5489;
        }

        .reward-rules {

          font-size: 12px;
          font-family: ArialMT;
          color: #4C5489;

          p {

            font-size: 12px;
            font-family: ArialMT;
            color: #4C5489;
            margin-bottom: 2px;
          }

          i {
            color: #7E5A3B;
          }
        }

        .num {
          font-size: 26px;
          font-weight: 900;
          color: #2B42A1;
        }

        .dao {
          font-size: 12px;
          font-weight: 900;
          color: #2B42A1;
        }

        .usd {
          color: #2B42A1;
          font-size: 16px;
          font-weight: 900;
        }

        .desc {
          padding-bottom: 18px;
          border-bottom: 1px solid #E3E3E3;
          color: #9799A1;
        }

        .way {
          margin-bottom: 18px;

          .way-title {
            font-weight: 900;
            color: #2B42A1;
            letter-spacing: -1px;
            margin-bottom: 18px;
            letter-spacing: 0.03rem;
          }

          .way-item {
            font-weight: 400;
            color: #2B42A1;
            margin-bottom: 14px;

            span {
              font-weight: 400;
              color: #2B42A1;
            }

            .tag {
              display: inline-block;
              background: #610EDA;
              border-radius: 11px;
              font-weight: 900;
              color: #FFFFFF;
              width: 30px;
              text-align: center;
              margin-right: 4px;
            }

            &:nth-of-type(3) {
              .tag {
                background: #0A0CF3;
              }
            }
          }
        }

        .record {
          .record-title {
            font-weight: 900;
            color: #2B42A1;
            margin-bottom: 10px;
          }

          .record-container {
            position: relative;
            height: 80px;

            .record-list {
              // position: absolute;

              .record-item {
                margin-bottom: 6px;

                .record-content {
                  font-weight: 400;
                  color: #9799A1;
                }

                .record-score {
                  font-weight: bold;
                  color: #2B42A1;
                }
              }

            }
          }
        }
      }

      button {
        --border-width: 1px;
        --border-radius: 20px;
        --color-1: #E191A5;
        --color-2: #3E7DFF;
      }

      .writer {
        border: none;
        position: relative;
        background: linear-gradient(to right, #E191A5, #3E7DFF), linear-gradient(to right, #E191A5, #3E7DFF);
        background-position: var(--border-radius) 0, var(--border-radius) 100%;
        background-repeat: no-repeat;
        background-size: calc(100% - var(--border-radius) - var(--border-radius)) var(--border-width);
        border-radius: var(--border-radius);

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: var(--border-radius);
          top: 0;
          bottom: 0;
          left: 1px;
          border: var(--border-width) solid var(--color-1);
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
          border-right-color: transparent;
        }

        &::after {

          content: "";
          display: block;
          position: absolute;
          width: var(--border-radius);
          top: 0;
          bottom: 0;
          border: var(--border-width) solid var(--color-2);
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
          border-left-color: transparent;
          right: 1px;
          border: var(--border-width) solid var(--color-2);
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
          border-left-color: transparent;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.top-bar {
  backdrop-filter: blur(4px);
}

.wallet-pop {
  position: fixed !important;

  .wallet-toast {
    width: 580px;

    .wallet-container {
      .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      .got-btn {
        width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        height: 40px;
        margin: 0 auto;
      }

      .got-it {
        flex: 1;
        text-align: center;
        padding: 40px 16px;
      }

      .wallet-info {
        flex: 1;
        padding: 40px 16px;
      }

      .info-title {
        font-weight: 900;
        color: #2B42A1;
        font-size: 18px;
        margin: 16px 0 20px;
      }

      .info-sec-title {
        font-weight: 900;
        color: #2B42A1;
        font-size: 15px;
        margin: 16px 0 14px;
      }

      .info-thd-title {
        font-weight: 500;
        color: #4A4E5E;
        font-size: 15px;
        margin: 15px 0 14px;
      }

      .info-p {
        text-align: justify;
      }
    }

  }
}

@media screen and (max-width:1000px) {

  .navbar-brand {
    display: none;
  }
}

@media screen and (max-width:767px) {

  .navbar-brand {
    display: block;
  }

  .nav-bar {
    backdrop-filter: blur(4px);
  }
}