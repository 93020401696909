.claim {
  .coin {
    display: block;
    width: 233px;
    margin: 0 auto;
  }

  .left-num {
    width: 100%;
    max-width: 587px;
    height: 82px;
    background: rgb(40, 38, 93);
    border-radius: 47px;
    margin: 45px auto 24px;

    .label {
      text-align: center;
      color: #FFFFFF;
      width: 100px;
      margin: 0 20px 0 27px;

      div {
        color: #FFFFFF;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;

        span {
          color: #FE7625;
        }
      }
    }

    .num {
      border-left: 1px solid #514F70;
      padding-left: 20px;
      color: #FFFFFF;
      flex: 1;
      font-size: 36px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #FFFFFF;
    }

    .claim-btn {
      text-align: center;
      width: 200px;
      margin-right: 10px;

      button {
        width: 100%;
        max-width: 188px;
        font-weight: 800;
        height: 57px;
        font-size: 18px;
        border-radius: 47px;
        background: linear-gradient(180deg, #DB8436 0%, #F14F6C 100%);
        border: none;
      }
    }
  }

  .sub {
    margin-bottom: 63px;

    div {
      font-size: 15px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #fff;
      line-height: 40px;
    }

    .tag {
      font-size: 15px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #FE7625;
    }
  }

  .token-num {
    font-size: 72px;
    color: #fff;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
  }

  .finished {
    color: #1EFF3A;
  }

  .token-sub {
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 65px;
  }

  .progress-box {
    align-items: flex-start;
    margin-bottom: 83px;

    .progress-customer {
      flex: 1;
      margin-right: 16px;

      .invite-progress {
        height: 43px;
        border-radius: 51px;
        background: #312F52;

        .progress-bar {
          font-size: 15px;
          font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
          font-weight: 800;
          color: #FFFFFF;
          overflow: unset;
          text-align: left;
          padding-left: 15px;
        }
      }

      .step {

        .item {
          margin-top: 30px;
          position: relative;

          .num {
            font-size: 36px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
          }

          .label {
            font-size: 18px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          }

          div {

            color: #B6B4BE;
          }
        }

        .active {
          div {
            color: #fff;
          }

          &::before {
            position: absolute;
            content: '';
            display: block;
            height: 13px;
            background: #3E7DFF;
            width: 13px;
            border-radius: 50%;
            left: 50%;
            top: -15px;
            transform: translate(-50%);
          }
        }
      }
    }

    .invite {
      width: 140px;
      height: 43px;
      border-radius: 47px;
      color: #fff;
      background: linear-gradient(180deg, #3176FD 0%, #3E3EFF 100%);
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN !important;
      font-weight: 800 !important;

      &:hover {
        background: linear-gradient(180deg, #1d489b 0%, #1717c7 100%);
      }

      .twitter-icon {
        width: 18px;
        margin-right: 4px;
      }
    }
  }

  .remain {
    text-align: center;
    font-size: 25px;
    color: #fff;
    margin-bottom: 35px;

    span {
      color: #1EFF3A;
    }
  }

  .finished-title {
    margin-bottom: 25px;
    text-align: center;
    font-size: 25px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #FFFFFF;

    span {
      color: #FFF01C;
    }
  }

  .finished-result {
    background-color: #312F52;
    width: 1124px;
    margin-bottom: 60px;

    .result-item {
      margin: 30px 0 43px;
      flex: 1;
      border-right: 1px solid #514F70;

      .num {
        margin-bottom: 10px;
        font-size: 36px;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
      }

      .label {
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .airdrop-amount2 {
    text-align: center;
    font-size: 130px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #FFFFFF;
  }

  .airdrop2-desc {
    text-align: center;
    font-size: 15px;
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
    font-weight: 800;
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  .show-off {
    margin-top: 20px;
    margin-bottom: 80px;
    width: 340px;
    height: 80px;
    font-size: 32px!important;
    border-radius: 47px;
    color: #fff;
    background: linear-gradient(180deg, #3176FD 0%, #3E3EFF 100%);
    font-family: SourceHanSansCN-Heavy, SourceHanSansCN !important;
    font-weight: 800 !important;

    &:hover {
      background: linear-gradient(180deg, #1d489b 0%, #1717c7 100%);
    }

    .twitter-icon {
      width: 24px;
      margin-right: 4px;
    }
  }
}