.catwoman-icon {
  width: 480px;
}

@media screen and (max-width: 900px) {
  .catwoman-icon {
    width: 300px;
  }
}

.nft-content {
  .title {
    margin-bottom: 28px;
    font-size: 42px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
  }

  .desc {
    font-size: 15px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #B6B4BE;
    margin-bottom: 46px;
  }

  .opensea {
    font-size: 18px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #B6B4BE;
    border-radius: 28px;
    border: 1px solid #B6B4BE;
    margin-bottom: 20px;

    img {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 900px) {

    .title {
      font-size: 26px;
    }

    .opensea {

      font-size: 14px;
    }
  }

}