.chapter-btn {
  position: fixed;
  right: 4%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.chapter-pop {
  position: fixed !important;
  margin-top: 88px;
  margin-right: 140px;
  .catwoman{
    margin: 18px 20px 28px;
  }
  .book-name {
    font-size: 20px;
    font-weight: 800;

    &>div {
      color: #2B42A1;
    }

    .book-author {
      font-size: 15px;
    }
  }

  .book-detail {
    max-height: 360px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
  
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #E8E8E8;
      border-radius: 10px;
      cursor: pointer;
    }
  
    &::-webkit-resizer {
      background: transparent
    }
  
    &::-webkit-resizer {
      background: transparent;
    }
  
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    .volume-container {
      .volume-title {
        font-weight: 800;
        color: #2B42A1;
        font-size: 15px;
        margin: 0 20px 20px;
      }

      .chapter-container {
        .chapter-item {
          font-size: 15px;
          font-weight: 400;
          color: #2B42A1;
          padding-left: 20px;
          margin-bottom: 10px;
          height: 24px;
          line-height: 24px;
          transition: all .2s;

          &.read {
            background: url('../../static/images/read.png') no-repeat;
            background-position: left center;
            background-size: 20px auto;
          }

          &:hover {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.translate-middle-x{
  margin-right: 0px;
}