.app-download {
  margin-bottom: 60px;
  .content {
    flex: 1;

    .title {
      font-size: 42px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 54px;
      margin-bottom: 28px;
    }

    .desc {
      margin-bottom: 44px;
      font-size: 15px;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #B6B4BE;
      line-height: 24px;
    }

    .google {
      position: relative;
      background: #062A2E;
      font-size: 18px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #FFFFFF;
      border-radius: 28px;
      &:hover{
        background: #0b555e;
      }
      img {
        margin-right: 10px;
      }
    }

    .btns {
      margin-bottom: 60px;
    }

    .android {
      font-size: 18px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #B6B4BE;
      border-radius: 28px;
      border: 1px solid #B6B4BE;

      img {
        margin-right: 10px;
      }
    }
  }

  .app-cover {
    text-align: center;
    flex: 1;
    .app{
      width:560px;
    }
    @media screen and (max-width: 1060px){
      .app{
        width:350px;
      }
    }
  }
}
@media screen and (max-width: 860px){
  .app-cover{
    display: none;
  }
}
