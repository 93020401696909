.assignment-detail{
  margin-bottom:40px;
  table{
    tr{
      th,td{
        padding:20px 0;
        border-bottom: 1px dashed rgba(181, 177, 220, 0.14);
        .total-td{
          width:90%;
          margin:10px auto 0;
          min-width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #06863E;
          color: #fff;
          font-size: 16px;
          font-weight: 900;
          height:44px;
          border-radius: 1px;
        }
      }
      th{
        color: #fff;
        font-weight: 900;
      }
    }
  }
}