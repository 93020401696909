@font-face {
  font-family: "SourceHanSansCN-Heavy";
  src: url('./static/fonts/SourceHanSansCN-Heavy.otf');
}
@font-face {
  font-family: "SourceHanSansCN-Normal";
  src: url('./static/fonts/SourceHanSansCN-Normal.otf');
}
@font-face {
  font-family: "FjallaOne";
  src: url('./static/fonts/FjallaOne-Regular.ttf');
}
@font-face {
  font-family: "RobotoCondensed";
  src: url('./static/fonts/RobotoCondensed-Regular.ttf');
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url('./static/fonts/RobotoCondensed-Bold.ttf');
}
@font-face {
  font-family: "RobotoCondensed-BoldItalic";
  src: url('./static/fonts/RobotoCondensed-BoldItalic.ttf');
}
@font-face {
  font-family: "RobotoCondensed-Italic";
  src: url('./static/fonts/RobotoCondensed-Italic.ttf');
}
@font-face {
  font-family: "RobotoCondensed-Light";
  src: url('./static/fonts/RobotoCondensed-Light.ttf');
}
@font-face {
  font-family: "RobotoCondensed-LightItalic";
  src: url('./static/fonts/RobotoCondensed-LightItalic.ttf');
}
*{
  margin:0;
  padding:0;
  color:#B5B3BD;
  font-family: "RobotoCondensed";
  &::-webkit-scrollbar {
    width: 12px;

  }

  &::-webkit-scrollbar-track {
    background: rgb(11,4,43);
  }

  &::-webkit-scrollbar-track-piece {
    background: rgb(11,4,43);
  }

  &::-webkit-scrollbar-thumb {
    background: #48438A;
    border-radius: 10px;
    cursor: pointer;
  }

  &::-webkit-resizer {
    background: rgb(11,4,43)
  }

  &::-webkit-resizer {
    background: rgb(11,4,43);
  }

  &::-webkit-scrollbar-corner {
    background: rgb(11,4,43);
  }
}
.heavy-title{
  font-family: "RobotoCondensed-Bold";
  font-weight: 900;
}
button{
  font-family: "RobotoCondensed-Bold";
  font-weight: 900;
}
$black: rgb(30,33,33);
$white: rgb(255,255,255);

%flex-row{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.flex-row{
    @extend %flex-row;
}
.flex-row-center{
    @extend %flex-row;
    justify-content: center;
}
.flex-row-wrap{
    @extend %flex-row;
    flex-wrap: wrap;
}
.flex-row-space-around{
    @extend %flex-row;
    justify-content: space-around;
}
.flex-row-space-between{
    @extend %flex-row;
    justify-content: space-between;
}
.flex-row-align-start{
    @extend %flex-row;
    align-items: flex-start;
}
.flex-row-align-end{
    @extend %flex-row;
    align-items: flex-end;
}
.flex-row-align-baseline{
    @extend %flex-row;
    align-items: baseline;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.block-left{
    display: block;
    margin-right:auto;
}
.block-center{
    display: block;
    margin:0 auto;
}
.block-right{
    display: block;
    margin-left:auto;
}
.hidden{
    display: none;
}
.border-bottom{
  border-bottom: 1px solid #E3E3E3;
}
@mixin text-line($i){
    word-break: break-all;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $i;
    line-clamp: $i;
    -webkit-box-orient: vertical;
}
@for $i from 1 to 5 {
    .text-line-#{$i}{
        @include text-line($i);
    }
}
@for $i from 12 to 40 {
    .f-#{$i}{
        font-size:#{$i}px;
    }
}

@for $i from 1 to 120 {
    .h-#{$i}{
        height:#{$i}px!important;;
    }
    .h-min-#{$i}{
        min-height:#{$i}px!important;;
    }
    .w-#{$i}{
        width:#{$i}px!important;
    }
    .m-g-t-#{$i}{
        margin-top:#{$i}px;
    }
    .m-g-b-#{$i}{
        margin-bottom:#{$i}px;
    }
    .m-g-l-#{$i}{
        margin-left:#{$i}px;
    }
    .m-g-r-#{$i}{
        margin-right:#{$i}px;
    }
    .p-d-t-#{$i}{
        padding-top:#{$i}px;
    }
    .p-d-b-#{$i}{
        padding-bottom:#{$i}px;
    }
    .p-d-l-#{$i}{
        padding-left:#{$i}px;
    }
    .p-d-r-#{$i}{
        padding-right:#{$i}px;
    }
}
.bg-white{
    background:$white;
}
.bg-black{
    background:$black;
}

.color-white{
    color:$white;
}
.color-black{
    color:$black;
}
.pointer{
    cursor: pointer;
}
:export {
    blackColor:$black;
    whiteColor:$white;
}