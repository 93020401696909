.token-card {
  width: 356px;
  padding-top: 20px;
  text-align: center;
  .card-index {
    font-size: 70px;
    font-weight: 900;
    color: #4582FF;
  }

  .card-title {
    font-size: 28px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 15px;
  }
}