.rules{
  border-top: 1px solid #55528E;
  position: relative;
  padding-top: 68px;
  .title{
    color:#fff;
    margin-bottom: 28px;
  }
  .content{
    padding-bottom: 80px;
    .item{
      margin-top: 20px;
      span{
        color:#1E64FF;
      }
    }
  }
  
}