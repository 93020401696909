.airdrop-1 {
    text-align: center;

    .coin-box {
        position: relative;
        padding: 100px 0 50px;

        .coin {
            position: absolute;
            left: 50%;
            top: 40px;
            transform: translateX(-50%);
        }

        .coin-num {
            font-size: 90px;
            font-weight: 900;
            color: #FFFFFF;
        }
    }

    .content {
        margin-top: 22px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: left;
    }

    .close-box {
        margin: 100px auto 18px;
    }

    .description {
        max-width: 700px;
        margin: 0 auto 30px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .tips {
        max-width: 700px;
        font-weight: bold;
        color: #F66BA6;
        margin: 0 auto 24px;
    }

    .open-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 44px;
        border-radius: 20px;
        margin: 0 auto 110px;
    }

    .invite-box {
        text-align: left;
        margin: 26px 0;
        padding: 0 12px;
    }

    .invite-title {
        text-align: left;
        padding: 0 12px;
        margin-bottom: 18px;
        font-weight: 800;
        color: #FFFFFF;
    }

    .invite-list {
        padding: 0 12px;
        .invite-item {
            border: 1px solid rgba(255, 255, 255, 0.09);
            height: 45px;
            border-radius: 4px;
            min-width: 120px;
            max-width: 200px;
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }

    .invite-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 156px;
        height: 45px;
        margin-bottom: 20px;
        border-radius: 26px;
    }

    .invite-history-title {
        padding: 0 12px;
        font-weight: 800;
        color: #FFFFFF;
        font-size: 40px;
        text-align: left;
        margin-top: 30px;
    }
    .invite-history-list{
        padding: 0 12px;
    }
    .clear-div{
        margin-bottom: 30px;
    }
}