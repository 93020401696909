.bussiness {
    text-align: center;
    width: 549px;
    height: 760px;
    .bussiness-title {
        margin-top: 40px;
        letter-spacing: 0.03rem;
        color: #fff;
        font-size: 35px;
        font-weight: 900;
        margin-bottom: 20px;
    }

    .bussiness-text-title {
        font-size: 18px;
        font-weight: 900;
        color: #fff;
        text-align: left;
        letter-spacing: 0.03rem;
        &::before {
            display: inline-block;
            content: '';
            width: 11px;
            height: 11px;
            margin-right: 10px;
            background-color: #fff;
            border-radius: 2px;
            transform: rotate(45deg);
        }
    }
    .bussiness-text-content{
        line-height: 29px;
        text-align: justify;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:600px) {
  .bussiness{
    width:96%;
    margin:0 auto;
     .bussiness-card{
      width:60%;
     }
  }
}