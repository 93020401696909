.dz-image-uploader{
    width: 100%;
    height: 100%;
    .upload-btn{
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        max-width: 380px;
        height: 100%;
        background-image: url('../../static/images/upload.png');
        background-repeat: no-repeat;
        background-size: 46px;
        background-position: center;
        background-color: #39356E;
        &:hover{
            box-shadow: 0 0 7px 2px rgb(13 110 253 / 25%);
        }
    }
}