.roadmap {
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(181, 177, 220, 0.15);

  &::after {
    display: block;
    content: '';
    height: 0;
    width: 0;
    clear: both;
  }

  .roadmap-time {
    line-height: 35px;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    position: relative;
    height: 470px;

    .roadmap-time-1 {
      width: 663px;
      background-color: #312F52;
      color: #fff;
      font-family: 'FjallaOne', sans-serif;
    }

    .roadmap-time-2 {
      width: 451px;
      background-color: #788BB3;
      color: #fff;
      font-family: 'FjallaOne', sans-serif;
    }

    .roadmap-q2 {
      position: absolute;
      left: 0;
      top: -50px;
      border-left: 1px solid #312F52;
      text-align: left;
      padding: 36px 20px 10px;

      .roadmap-title {
        text-align: left;
        font-size: 18px;
        margin-bottom: 14px;
        font-weight: 900;
        color: #fff;
        font-family: 'FjallaOne', sans-serif;
      }
    }

    .roadmap-q4 {
      position: absolute;
      left: 451px;
      top: 20px;
      border-left: 1px solid #312F52;
      text-align: left;
      padding: 36px 20px 10px;

      .roadmap-title {
        text-align: left;
        font-size: 18px;
        margin-bottom: 14px;
        font-weight: 900;
        color: #fff;
        font-family: 'FjallaOne', sans-serif;
      }
    }

    .roadmap-q3 {
      position: absolute;
      left: 0px;
      top: 250px;
      border-right: 1px solid #312F52;
      text-align: left;
      padding: 10px 50px 10px 20px;

      .roadmap-title {
        text-align: left;
        margin-bottom: 14px;
        font-size: 18px;
        font-weight: 900;
        color: #fff;
        font-family: 'FjallaOne', sans-serif;
      }
    }

    .roadmap-q1 {
      position: absolute;
      right: 0px;
      top: 250px;
      border-right: 1px solid #788BB3;
      text-align: left;
      padding: 20px 10px;
      // text-indent: 2em;

      .roadmap-title {
        text-align: left;
        font-size: 18px;
        margin-bottom: 14px;
        font-weight: 900;
        color: #fff;
        font-family: 'FjallaOne', sans-serif;
      }
    }

    .roadmap-item {
      font-family: 'RobotoCondensed', sans-serif;
      // display: flex;
      // font-family: SourceHanSansCN-Normal, SourceHanSansCN;
      font-weight: 400;
      color: #B5B3BD;
      font-size: 15px;
      align-items: center;

      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #B6B4BE;
        border-radius: 2px;
        transform: rotate(45deg);
        margin-right: 6px;
        top: -3px;
        position: relative;
      }
    }
  }
}

@media screen and (max-width:1040px) {
  .roadmap {

    .roadmap-time {
      display: block;
      height: auto;
      position: relative;
      .roadmap-time-1,
      .roadmap-time-2 {
        margin:0 auto;
        width:90%;
        min-width: 310px;
        // position: absolute;
        // width: 18px;
        // height: 18px;
        // border-radius: 50%;
        // text-indent: 2rem;
        // line-height: 18px;
        // z-index: 99;
        // left:20px;
        // &::after {
        //   display: inline-block;
        //   z-index: 9;
        //   position: absolute;
        //   left: 8px;
        //   content: '';
        //   height: 760px;
        //   width: 4px;
        //   background: #312F52;
        // }
      }

      // .roadmap-time-2 {
      //   top: 800px;

      //   &::after {
      //     height:380px;
      //     background: #788BB3;
      //   }
      // }

      .roadmap-q1,
      .roadmap-q2,
      .roadmap-q3,
      .roadmap-q4 {
        position: relative;
        left: 0;
        top: 0;
        width:90%;
        min-width: 320px;
        margin: 0 auto;
        border:none;
      }
    }
  }

}