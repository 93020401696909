.earn-card {
  .card-content {
    .card-title {
      font-size: 18px;
      font-family: "RobotoCondensed-bold";
      font-weight: 800;
      color: #FFFFFF;
      line-height: 18px;
      margin-bottom: 25px;

      span {
        display: inline-block;
        color: #fff;
        text-align: center;
        width: 44px;
        height:22px;
        line-height: 22px;
        background: #610EDA;
        border-radius: 11px;
        margin-right: 10px;
        font-family: 'RobotoCondensed', sans-serif;
      }
    }
    .blue{
      span{
        background-color: #0A0CF3;

      }
    }
    .card-main {
      width: 292px;
      margin-bottom: 22px;
      font-size: 15px;
      // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #B5B3BD;
    }

    .collect-btn {
      display: flex;
      align-items: center;
      height: 34px;
      padding-left: 25px;
      padding-right: 25px;
      border-radius: 20px;
      color: #fff;
    }
  }
}

@media screen and (max-width:493px) {
  .earn-card .card-content .collect-btn {
    margin: 0 auto 20px;
  }
}