.mint-container {
    width: 710px;
    height: 82px;
    background-color: #312F52;
    border-radius: 47px;
    margin: 0 auto 45px;

    .key {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
        font-size: 12px;
        width: 70px;
        text-align: center;
        margin: 0 30px;
        display: flex;
        justify-content: center;
        border-right: 1px solid #514F70;
    }

    .val {
        font-size: 36px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
    }

    .mint-box {
        width: 100%;
        border-radius: 47px;
        height: 82px;
        padding: 0 13px;
        background-color: #434E93;
        text-align: left;
        .key {
            border-right: 1px solid #5E6BBE;
        }

        .mint-btn {
            height: 57px;
            width: 138px;
            background: linear-gradient(180deg, #FFC73C 0%, #FEB431 100%);
            border-radius: 47px;
            font-size: 18px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 800;
            color: #350166;
            cursor: pointer;
            &.disabled-btn{
                cursor: not-allowed;
                filter: grayscale(0.5);
            }
        }
    }
}

.mission {
    margin: 0 auto;
    text-align: center;

    b {

        font-size: 15px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
    }

    a {
        color: #FEBB35;
        cursor: pointer;
        text-decoration: none;
    }

    button {

        height: 25px;
        background: #3E7DFF;
        border-radius: 28px;
        font-size: 13px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
    }
}

.claim-rules {
    border-top: 1px dashed #4B5071;
    margin-top: 52px;

    .rule-title {
        height: 70px;
        line-height: 70px;
        font-size: 15px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
    }

    .rule-item {
        margin-bottom: 12px;
        font-size: 15px;
        font-family: AppleColorEmoji;
        color: #B6B4BE;
    }
}
.rule-img{
    width: 100%;
    height:auto;
    margin: 0 auto;
    display: block;
    margin-bottom: 62px;
}
.verify-ok{
    width:25px;
    height:25px;
    margin:0 10px;
}