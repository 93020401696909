.read {
  .read-title {
    height: 110px;
    padding-bottom: 36px;
    border-bottom: 1px solid rgba(181, 177, 220, 0.15);
    font-weight: 400;
    color: #B5B3BD;

    span {
      font-weight: 800;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      font-size: 12px;
      height: 30px;
      padding-left: 24px;
      padding-right: 24px;
      text-indent: 16px;
      background-image: url('../../static/images/share.png');
      background-position: 22px 50%;
      background-repeat: no-repeat;
      background-size: 14px 14px;
    }
  }

  .read-main {
    padding: 0 60px 80px;

    .read-chapter {
      font-weight: 400;
      color: #FFFFFF;
      font-size: 32px;
      padding: 50px 0 40px;
    }

    .read-content {
      text-align: justify;
      font-size: 22px;
      line-height: 2.6rem;
      font-weight: 400;
      white-space: pre-wrap;
    }
  }

  .read-cost {
    margin: 50px 0 100px;

    .cost-info {
      .info-item {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.08);

        .info-tag {
          width: 220px;
          text-align: left;
        }
      }
    }

    button {
      width: 270px;
      border-radius: 30px;
      font-size: 24px;
      font-weight: 800;
      height: 56px;
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.reader-take {
  div {

    font-size: 12px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    color: #4C5489;
  }

  .desc {
    // font-family: 'SourceHanSansCN-Regular';
    padding: 0 0 32px;
    margin: 0 10px 44px;
    border-bottom: 1px solid #E3E3E3;
    font-weight: 400;
    color: #9799A1;
    font-size: 12px;
    text-align: justify;

  }

  .coin {
    text-align: center;
    color: #2B42A1;

    .coin-img {
      margin-bottom: 30px;
    }

    span,
    div {
      color: #2B42A1;
      font-weight: 800;
    }

    .num {
      font-size: 25px;
      
      font-size: 60px;
      font-family: Arial, Arial;
      font-weight: normal;
      color: #804DD7;
    }

    .tag {
      font-size: 12px;
    }

    .usd {
      font-size: 15px;
    }
  }

  .take-btn {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;

    button {
      border-radius: 20px;
      font-size: 15px;
      font-weight: 800;
      width: 165px;
      height: 40px;
      margin: 0 auto;
    }
  }
}