.story-info {
  .story-tips {
    margin: 40px 0;
    padding: 0 20px;
  }

  .story-main {
    .story-cover {
      max-width: 200px;
      text-align: center;
      margin: 0 10px;

      .cover-upload {
        height: 210px;
      }

      .images-tips {
        text-align: justify;
        font-size: 14px;
      }
    }

    .story-content {
      width: calc(100% - 200px);
      padding: 0 10px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 200px;
    border-radius: 20px;
    height: 44px;
    margin-right: 10px;
    margin-bottom: 10px;
    ;

  }
}

@media screen and (max-width:767px) {
  .story-info {
    .story-main {
      justify-content: center;
      flex-wrap: wrap;

      .story-content {
        width: 100%;
      }

      .dz-form-item {
        flex-wrap: wrap;

        .dz-form-label {
          text-align: left;
        }

        .btns-group {
          justify-content: center;
        }
      }
    }
  }

}