.airdrop-3 {
  .title {
    font-weight: 500;
    color: #FFFFFF;
    font-size: 15px;
    margin: 22px 0 44px;
    font-weight: 500;
  }

  .tip {
    font-weight: 800;
    color: #FFFFFF;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .mint {
    margin-bottom: 40px;

    .mint-num {
      font-weight: 900;
      color: #FFFFFF;
      font-size: 37px;
      margin-right: 30px;
    }

    .mint-button {
      border-radius: 20px;
      height: 38px;
      width: 120px;
    }
  }

  .contract {
    font-size: 15px;
    color: #FFFFFF;
    margin-bottom: 74px;
  }

  .minted {
    padding: 30px 50px;
    margin-bottom: 290px;
    .minted-title {
      font-weight: 800;
      color: #FFFFFF;
      font-size: 40px;
      margin-bottom: 40px;
    }

    .minted-list {
      .minted-header {
        font-weight: 800;
        color: #FFFFFF;
        font-size: 20px;
        margin-bottom: 18px;
      }

      .minted-item {
        margin-bottom: 10px;
        .price {
          font-weight: 800;
          color: #C8FFF1;
        }
      }
      &>div{
        &>div:nth-child(2){
          text-align: center;
        }
        &>div:last-child{
          text-align: right;
        }
        &>div{
          width:33.33%
        }
      }
    }
  }
}