.loading{
  position: fixed;
  left: 0;
  top:0;
  z-index: 99999999999;
  width:100%;
  height: 100vh;
  background: rgba($color: #ccc, $alpha: 0.3);
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  .load{
    position: relative;
    div{
      display: inline-block;
      position: absolute;
      width: 20px;
      height:44px;
      animation: animate infinite 1000ms ease-in-out;
      &:nth-of-type(1){
        transform: translateX(-100px);
        animation-delay: 0ms;
      }
      &:nth-of-type(2){
        transform: translateX(-80px);
        animation-delay: 60ms;
      }
      &:nth-of-type(3){
        transform: translateX(-60px);
        animation-delay: 120ms;
      }
      &:nth-of-type(4){
        transform: translateX(-40px);
        animation-delay: 180ms;
      }
      &:nth-of-type(5){
        transform: translateX(-20px);
        animation-delay: 240ms;
      }
      &:nth-of-type(6){
        transform: translateX(0px);
        animation-delay: 300ms;
      }
      &:nth-of-type(7){
        transform: translateX(20px);
        animation-delay: 360ms;
      }
      &:nth-of-type(8){
        transform: translateX(40px);
        animation-delay: 420ms;
      }
      &:nth-of-type(9){
        transform: translateX(60px);
        animation-delay: 480ms;
      }
      &:nth-of-type(10){
        transform: translateX(80px);
        animation-delay: 540ms;
      }
    }
  }
}
@keyframes animate {
  0%{
    top: 0px;
  }
  25%{
    top: -8px;
  }
  50%{
    top: 0px;
  }
  100%{
    top: 0px;
  }
}