.home{
    .card-container{
        
    }
}

@media screen and (max-width:1130px) {
    .card-container{
        justify-content: center;
        flex-wrap: wrap;
        &>div{
            margin-bottom: 30px;
        }
    }
}