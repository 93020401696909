.App{
  min-height:100vh;
  // min-width: 1100px;
  background:
  // url('./static/images/hero-shape-1.svg') left top no-repeat,
  // url('./static/images/hero-shape-2.svg') no-repeat right top,
  url('./static/images/bgl.png') left top no-repeat,
  url('./static/images/bgr.png') right top no-repeat,
  url('./static/images/footer-shape-1.svg') no-repeat right bottom,
  linear-gradient( 180deg, rgba(15, 6, 63, 100), rgba(8, 3, 33, 100) ); 
  background-size: 40% auto,25% auto,auto,auto;
  &>.container{
    margin:0 auto;
    max-width: 1128px;
    padding:0;
    padding-top: 110px;
  }
}