.phase {
  .phase-item {
    width: 100%;
    background: #14102C;
    border-radius: 2px;
    position: relative;
    padding: 122px 40px 60px;
    margin-bottom: 54px;

    &>.title {
      position: absolute;
      width: 256px;
      height: 62px;
      top: 0;
      line-height: 62px;
      text-align: center;
      left: 40px;
      background: #FE7625;
      border-radius: 0px 0px 22px 22px;
      font-size: 18px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 800;
      color: #FFFFFF;
    }

    .main {
      align-items: flex-start;

      .content {
        width: 590px;

        .result {
          width: 590px;
          height: 136px;
          border: 1px dashed #55528E;
          text-align: center;
          margin-bottom: 35px;

          .give-away {
            width: 273px;
            border-right: 1px dashed #55528E;
          }

          .holders {
            flex: 1;
          }

          .num {
            font-size: 35px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 36px;
            margin-bottom: 20px;
          }

          .tag {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 14px;
          }
        }

        .desc {
          margin-bottom: 34px;

          span {
            color: #1EFF3A;
          }
        }
      }

      .rewards {
        width: 416px;

        .box {
          width: 100%;
          background: #1A1343;
          border-radius: 9px;
          text-align: center;
          padding: 38px 20px;

          .num {
            font-size: 100px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 130px;
          }

          .desc {
            font-size: 18px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 40px;
          }

          .lg-btn {
            width: 200px;
            height: 51px;
            background: linear-gradient(180deg, #3176FD 0%, #3E3EFF 100%);
            border-radius: 31px;
            font-size: 18px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #FFFFFF;
          }

          .sorry {
            margin-top: 22px;
            color: #FC413E;
            font-size: 15px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
          }
        }
      }
    }

    &:last-of-type {
      >.title {
        background: #336DFD;
      }
    }
  }
}