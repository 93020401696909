.about-coin {
  padding: 50px 0 250px;
  border-bottom: 1px solid rgba(181, 177, 220, 0.15);

  .about-coin-title {
    letter-spacing: 0.03rem;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
  }
  .coin-main{
    margin-right: 40px;
  }
  .coin-sub {
    text-align: justify;
    margin-bottom: 30px;
  }

  .coin-info {
    border-bottom: 1px dashed rgba(181, 177, 220, 0.15);
    height: 50px;
    line-height: 50px;

    .coin-key {
      width: 200px;
    }

    .coin-val {
      width: calc(100% - 200px);
      word-break: break-all;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }
  }
  .buy-btn{
    font-family: "RobotoCondensed-bold";
  }
  button {
    border-radius: 30px;
    padding-left: 36px;
    padding-right: 36px;
    height: 56px;
    font-size: 24px;
    font-weight: 800;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width:1130px) {
  .about-coin {
    .about-coin-title,.coin-main{
      margin: 0px 20px;
    }
     button{
      margin:0 auto;
    }
  }
  .coin-image {
    display: none;
  }
}