.dz-form-item {
    margin-bottom: 20px;

    .dz-form-label {
        font-weight: 800;
        min-width: 134px;
        width:25%;
        height: 40px;
        line-height: 40px;
        text-align: right;

        &.required::before {
            content: '* ',
        }
    }

    .dz-form-content {
        width: 100%;
        padding: 0 10px;
    }
}