.coin-desc {
  margin-bottom: 70px;
  padding-bottom: 86px;
  border-bottom: 1px solid #212548;
  .coin-content {
    flex: 1;
    margin-right: 20px;

    .title {
      margin-bottom: 35px;
      font-size: 42px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #FFFFFF;
      margin-bottom: 14px;
    }

    .info {
      margin-bottom: 14px;
      font-size: 18px;
      font-family: ArialMT;
      color: #B6B4BE;

      .info-item {
        margin: 0 10px;

        &:nth-of-type(1) {
          margin-left: 0;
        }
      }

      span {
        font-weight: bold;
      }
    }

    .process {
      margin-bottom: 18px;
      font-size: 15px;
      font-family: ArialMT;

      .process-item {
        margin: 0 10px;

        &:nth-of-type(1) {
          margin-left: 0;
          color: #3B3BF6;
        }

        &:nth-of-type(2) {
          color: #90D7FC;
        }

        &:nth-of-type(3) {
          color: #CD560F;
        }

        &:nth-of-type(4) {
          color: #9138D5;
        }
      }
    }

    .process-bar {
      margin-bottom: 42px;
      height: 10px;
      width: 94%;

      >div {
        height: 10px;

        &:nth-of-type(1) {
          width: 60%;
          background: #3B3BF6;
        }

        &:nth-of-type(2) {
          width: 20%;
          background: #90D7FC;
        }

        &:nth-of-type(3) {
          width: 13%;
          background: #CD560F;
        }

        &:nth-of-type(4) {
          width: 7%;
          background: #9138D5;
        }
      }

    }

    .btns {
      margin-bottom: 18px;

      .coinbtn {
        font-size: 15px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #B6B4BE;
        border-radius: 28px;
        border: 1px solid #B6B4BE;
      }
    }
  }
}

@media screen and (max-width: 986px) {
 
  .coin-desc {
    display: block;
  }
}