.one-pass-card {
  text-align: center;
  width: 547px;
  height: 760px;

  .pass-card {
    display: block;
    margin: 20px auto 0;
    -webkit-animation: jumpY 5s infinite;
    -o-animation: jumpY 5s infinite;
    animation: jumpY 5s infinite;
  }

  .pass-card-title {
    letter-spacing: 0.03rem;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  .pass-card-size {
    margin-bottom: 44px;
    text-align: left;
    font-size: 14px;
  }

  .progress {
    background-color: #312F52;
    .progress-bar{
      border-radius: 0 20px 20px 0;
    }
  }

  .progress-text {
    position: relative;
    margin-bottom: 100px;

    .progress-item {
      font-size: 20px;
      color: #fff;
      display: inline-block;
      max-width: 80px;
      position: absolute;
      .item-num{
        font-size: 24px;
        font-weight: 800;
      }
      div {
        color: #fff;
      }
    }
  }

  .mint-earn-btn {
    border-radius: 20px;
    padding: 0.75rem 2rem;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

@keyframes jumpY {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px)
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }
}
@media screen and (max-width:600px) {
  .one-pass-card{
    width:96%;
    margin:0 auto;
     .pass-card{
      width:60%;
     }
     .progress-item:nth-of-type(2){
      display: none;
     }
     .progress-item:nth-of-type(4){
      display: none;
     }
  }
}