.dz-input {
    input {
        width: 100%;
        background: transparent;
        border: 1px solid #48438A;
        border-radius: 4px;
        margin: 0 4px;
        padding:0 .5rem;
        height:40px;

        &:focus{
            outline: #48438A;
            box-shadow: 0 0 7px 2px rgb(13 110 253 / 25%);

        }
    }
}