.dz-form-textarea {
  textarea {
    color:#fff;
    width: 100%;
    background: transparent;
    border: 1px solid #48438A;
    border-radius: 4px;
    margin: 0 4px;
    padding: 0 .5rem;
    height: 680px;

    &:focus {
      outline: #48438A;
      box-shadow: 0 0 7px 2px rgb(13 110 253 / 25%);

    }

    &::-webkit-scrollbar {
      width: 8px;

    }

    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }

    // &::-webkit-scrollbar-track-piece {
    //   background: transparent;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #48438A;
    //   border-radius: 10px;
    //   cursor: pointer;
    // }

    // &::-webkit-resizer {
    //   background: transparent
    // }

    // &::-webkit-resizer {
    //   background: transparent;
    // }

    // &::-webkit-scrollbar-corner {
    //   background: transparent;
    // }
  }
}