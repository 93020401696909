.book {
  text-align: center;

  .book-name {
    margin: 65px 0;
    font-size: 40px;
    font-weight: 900;
    color: #fff;
  }

  .book-description {
    text-align: justify;
    padding-bottom: 100px;
    line-height: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }

  .book-author {
    margin: 50px 0 30px;
    text-align: left;

    .author-tag {
      width: 70px;
    }

    .author-name {
      font-weight: 900;
      color: #FFFFFF;
    }
  }

  .author-info {
    text-align: justify;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }

  .read-cost {
    margin: 50px 0 100px;

    .cost-info {

      .info-item {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.08);

        .info-tag {
          width: 220px;
          text-align: left;
        }
      }
    }

    button {
      width: 270px;
      border-radius: 30px;
      font-size: 24px;
      font-weight: 900;
      height: 56px;
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width:1165px) {

  .App .book {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width:965px) {

  .read-cost {
    flex-wrap: wrap;
    justify-content: center;

  }
}

@media screen and (max-width:940px) {

  .read-cost {

    .cost-info {
      margin-bottom: 30px;
    }
  }
}