.dz-pop{
    position: fixed!important;
    .toast-body{
        min-height: 60px;
    }
    .close-btn{
        float:right;
    }
    .toast-content{
        margin-top: 30px;
    }
}