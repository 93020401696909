.story-card {
  width: 510px;
  margin-bottom: 30px;

  .story-info {
    width: 341px;
  }

  .clear-div {
    padding: 25px 10px;

    .story-cover {
      margin-right: 10px;
    }

    .story-title {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 800;
      color: #B5B3BD;
      max-width: 300px;
      word-break: break-all;
    }

    .story-author,
    .story-description {
      font-weight: 400;
      color: #B5B3BD;
      margin-bottom: 10px;
      width: 300px;
      word-break: break-all;

      .tag {
        width: 70px;
      }

      .content {
        font-weight: 800;
        color: #B5B3BD;
        width: calc(100% - 70px);
        word-wrap: break-word;
      }
    }

    .story-status {
      button {
        min-width: 80px;
        width: auto;
        max-width: 200px;
        padding: 5px;
        height: 30px;
        font-size: 12px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button+button {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width:567px) {
  .story-card {
    width: 90%;

    .story-info {
      width: calc(100% - 120px);

      .story-author,
      .story-description {
        width: 100%;
      }

      .story-status {
        flex-wrap: wrap;

        button {
          margin-right: 10px;

          +button {
            margin-left: 0;

          }
        }
      }
    }

    .clear-div .story-cover {
      width: 100px;
    }
  }
}