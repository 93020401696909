.nft-icoins {
    .nft-list-container{
        // background-color: red;
        overflow: hidden;
        padding-bottom: 70px;
        border-bottom:1px solid #212548;
        margin-bottom: 58px;
        &::after{
            content: '';
            height: 0;
            width: 0;
            clear: both;
            display: block;
        }
        .nft-list{
            float: left;
            width: 100%;
            // left:0;
            // height: 200px;
            .nft-item{
                width:134px;
                height: 134px;
                min-width: 134px;
                background-color: #fff;
                margin:0 10px 10px 0;
                background: url('../../../../static/images/nfts.png') no-repeat left top ;
                background-size: auto 280px;
            }
        }
    }
}
.swiper-wrapper{
}
