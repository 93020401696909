.book-list{
    padding: 0px 0 120px;
    border-bottom: 1px solid rgba(181, 177, 220, 0.15);
    // .swiper-slide{
        .book-item{
            display: inline-block;
            width:calc(1128px / 5);
            text-align: center;
            img{
              transform: scale(0.8);
              transition: all .5s;
              cursor: pointer;
              &:hover{
                  transform: scale(1);
              }
            }
           
        }
    // }
}
// @media screen and (max-width:771px) {
//   .book-list{
//     &::after{
//       width:25%;
//     }
//   }
// }