.supported-by{
    .supported-title{
        font-size: 25px;
        padding: 20px 0 30px;
        font-weight: 900;
        color: #fff;
    }
    .supported-list{
        .supported-item{
            width:160px;
            margin-bottom: 20px;
            .item-text{
                margin: 0 20px;
            }
        }
    }
}