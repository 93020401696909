.question-div {
    padding: 1px 32px 20px 32px;
    background-color: #312F52;
    margin-top: 20px;
    border-radius: 5px;
    border-top: 5px solid #464377;
    
    .question-title {
        margin: 26px 0 10px 0;
        font-size: 18px;
        font-weight: 900;
        color: #fff;
    }
    .question-content{
        text-align: justify;
    }
}