.volume-view {
    padding: 0 10px;
    margin-bottom: 160px!important;
    .volume-header {
        padding: 40px 0;
        border-bottom: 1px solid rgba(181, 177, 220, 0.15);

        .volume-title {
            margin: 0 0 14px;
            font-weight: 800;
            font-size: 20px;
        }

        .volume-desc {
            margin: 0 0 22px;
            font-size: 12px;
        }

    }

    .volume-content {
        margin-top: 60px;

        .volume-item {
            margin-bottom: 20px;
            &:not(:first-of-type){
                margin-top:40px;
            }
            button{
                font-size: 12px;
                height:30px;
            }
            .volume-item-title {
                max-width: 50%;
                font-weight: 800;
                font-size: 15px;
                color: #fff;
                margin-bottom: 10px;
            }
        }
        .chapter-item{
          margin-bottom: 10px;
            .chapter-item-title{
                font-size: 15px;
                color: #fff;

            }
            img{
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
                &+img{
                    margin-left:10px;
                }
            }
        }
        .active-chapter{
          margin:20px 0;
        }
    }

    button {
        display: flex;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        border-radius: 20px;
        color: #fff;
        height: 44px;
        font-weight: 800;
        font-size: 15px;

        &+button {
            margin-left: 14px;
        }
    }
    .pop-content{
        color:#2B42A1;
        font-weight: 800;
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
    }
    .pop-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin: 20px auto;
    }
}