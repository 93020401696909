.airdrop-2 {
  margin-top: 54px;

  .airdrop-tag {
    float: right;
    margin-right: 22px;
    margin-top: 22px;
  }

  .airdrop-timer {
    font-size: 70px;
    color: #fff;
    margin: 20px 0;
    font-weight: 900;
  }

  .content {
    font-weight: 500;
    color: #B5B3BD;
    font-size: 18px;
    margin-bottom: 20px;

    span {
      font-weight: 900;
      color: #fff;
    }
  }

  .nft {
    margin-bottom: 20px;
  }

  .buy {
    .buy-btn {
      border-radius: 20px;
      width: 144px;
    }
  }
  .onepass{
    width: 330px;
    margin-right: 10px;
  }
  .right {
    font-weight: 800;
    color: #FFFFFF;
    font-size: 35px;
    margin-bottom: 16px;
  }

  .right-title {
    font-weight: 800;
    color: #B6B4BE;
    font-size: 18px;
    margin-bottom: 16px;
  }

  .right-list {
    div {
      font-weight: 400;
      color: #B6B4BE;
      padding-left: 28px;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 28px;
      background: url('../../../../static/images/right.png') no-repeat 8px 8px;
      background-position: 0px 4px;
    }
  }
}